export default defineNuxtRouteMiddleware(() => {
	// use useRouteGuard() instead
	// this code is working but it Too slow. The page is loading in time and only after 0.5-1s navigate to main page
	if (!process.client) {
		return;
	}

	const isGuest = useIsGuest();
	if (isGuest.value === true) {
		return navigateTo("/", { external: true });
	}
});
